import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next';
import { Oidc } from '@axa-fr/react-oidc/dist/vanilla';
import { getAllianzHeaders } from '../util/allianz-cookie';

const randomHex = function (len) {
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r += randomHex(len - maxlen);
  }
  return r;
};
const traceParentTraceId = '-' + randomHex(16) + '-01';

const traceParent = () => '00-' + randomHex(32) + traceParentTraceId;

const toPagedListData = (data, page = 1, pageSize = 20, totalPages = 0, totalCount = 0) => {
  if (!totalCount) totalCount = Math.max(data.length, 0);
  if (!totalPages && +pageSize > 0) totalPages = Math.max(totalCount / pageSize, 1);
  return {
    data: data,
    page: page,
    totalPages: totalPages,
    totalCount: totalCount
  };
};

const copyParameterToUrl = (parameter) => {
  let urlParam = '';
  if (parameter)
    for (const paramName in parameter) {
      if (parameter.hasOwnProperty(paramName) && parameter[paramName]) {
        urlParam += `${urlParam.length > 0 ? '&' : '?'}${encodeURIComponent(paramName)}=${encodeURIComponent(
          parameter[paramName]
        )}`;
      }
    }
  return urlParam;
};

function getAuthorizationHeader() {
  const accessToken = Oidc.get().tokens.accessToken;
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
}

function getHeaders() {
  return {
    ...getAuthorizationHeader(),
    ...getAllianzHeaders(),
    'Content-Type': 'application/json; charset=utf-8',
    'X-Request-Id': uuidv4(),
    'Accept-Language': i18n.language || 'de',
    traceparent: traceParent()
  };
}

export const get = async (url, queryParams) => {
  return fetch(url + copyParameterToUrl(queryParams), {
    method: 'GET',
    headers: getHeaders()
  });
};

export const fetchResponse = async (baseUrl) => {
  return await fetchResponseForParameter(baseUrl);
};

export const fetchResponseForParameter = async (baseUrl, parameter) => {
  const response = await fetch(baseUrl + copyParameterToUrl(parameter), {
    method: 'GET',
    headers: getHeaders()
  });
  if (!response.ok) {
    throw new Error(response.status);
  }
  if (response.status !== 204) {
    return await response.json();
  }
};

export const getDocumentDownloadUrl = async (url) => {
  const response = await get(url);
  return response.headers.get('location');
};

export const getDocumentUploadUrl = async (url) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: getHeaders()
  });
  return response.headers.get('location');
};

export const fetchListForParameter = async (
  baseUrl,
  parameter,
  page,
  pageSize,
  sort,
  filterDuplicatedPolicies,
  filterAbandonedDrafts
) => {
  parameter = { ...parameter, totalRequired: true };
  if (page > 0) parameter = { ...parameter, page: page };
  if (pageSize > 0) parameter = { ...parameter, pageSize: pageSize };
  if (sort) parameter = { ...parameter, sort: sort };
  if (filterDuplicatedPolicies) parameter = { ...parameter, filterDuplicatedPolicies: true };
  if (filterAbandonedDrafts) parameter = { ...parameter, filterAbandonedDrafts: true };
  const response = await fetch(baseUrl + copyParameterToUrl(parameter), {
    method: 'GET',
    headers: getHeaders()
  });
  if (response.status === 404) {
    return toPagedListData([], 1, 0, 0, 0);
  }
  if (!response.ok) throw new Error(response.status);
  if (response.status === 204) {
    return toPagedListData(
      [],
      page,
      pageSize,
      response.headers.get('Total-Pages'),
      response.headers.get('Total-Items')
    );
  }
  const data = await response.json();
  if (data.data && Array.isArray(data.data)) {
    return data;
  }
  const resultData = Array.isArray(data) ? data : data.results || [];
  return toPagedListData(
    resultData,
    page,
    pageSize,
    response.headers.get('Total-Pages'),
    response.headers.get('Total-Items')
  );
};

function handleErrorWithRetry(response, retryFn, errorMessage) {
  const retryAfter = response.headers.get('retry-after');
  if (response.status === 503 && retryAfter) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(retryFn()), retryAfter * 1000);
    });
  }
  throw new Error(`${response.status} ${errorMessage}`);
}

export const putContent = async (url, content) => {
  console.log('putContent', url);
  const response = await fetch(url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(content)
  });
  console.log('putContent', response);

  if (!response.ok) {
    return handleErrorWithRetry(response, () => putContent(url, content));
  }

  return response.status === 200 ? await response.json() : undefined;
};

export const patchContent = async (url, content) => {
  console.log('patchContent', url);
  const response = await fetch(url, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(content)
  });
  console.log('patchContent', response);

  const text = await response.text();
  const json = JSON.parse(text || '{}');

  if (!response.ok) {
    return handleErrorWithRetry(response, () => patchContent(url, content), json.message);
  }

  return response.status === 200 ? json : undefined;
};

export const postContent = async (url, content) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(content)
  });

  if (!response.ok) {
    return handleErrorWithRetry(response, () => postContent(url, content));
  }

  return await response.json();
};

export const deleteUrl = async (url) => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: getHeaders()
  });
  const text = await response.text();

  if (!response.ok) {
    return handleErrorWithRetry(response, () => deleteUrl(url), text);
  }

  if (text) {
    return JSON.parse(text);
  } else {
    return undefined;
  }
};

export const postUpload = (url, file) => {
  console.log('postUpload', url, file);
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'X-Request-Id': uuidv4(),
      traceparent: traceParent()
    }
  });
};

export const getUploadUrl = async (url) => {
  const result = await fetch(url, {
    method: 'POST',
    headers: getHeaders()
  });

  return result.headers.get('Location');
};

export const downloadContentAsFile = async (url, fileName, fileType) => {
  const contentResult = await fetch(url);
  const content = await contentResult.blob();
  content.name = fileName;
  content.options = { type: fileType };
  return content;
};
