import PermissionsContext from './PermissionsContext.js';
import { useRef } from 'react';
import { checkPermission } from '../../../apis/resource-manager-api.js';

export default function PermissionsProvider({ children }) {
  const permissionsCache = useRef({});
  const checkPermissionWithCache = async (actionKey) => {
    if (Object.hasOwn(permissionsCache.current, actionKey)) {
      return permissionsCache.current[actionKey];
    } else {
      const promise = checkPermission([actionKey]);
      promise.catch((err) => {
        console.error(`Permission check failed for ${actionKey}.`, err);
        delete permissionsCache.current[actionKey];

        return true;
      });

      permissionsCache.current[actionKey] = promise;
      return promise;
    }
  };

  const checkComplexPermissions = async (permissions) => {
    if (typeof permissions === 'string') {
      return checkPermissionWithCache(permissions);
    } else {
      const promises = [];
      if (permissions.allOf) {
        promises.push(
          Promise.all(permissions.allOf.map((permission) => checkComplexPermissions(permission))).then((results) =>
            results.every(Boolean)
          )
        );
      }
      if (permissions.oneOf) {
        promises.push(
          Promise.all(permissions.oneOf.map((permission) => checkComplexPermissions(permission))).then((results) =>
            results.some(Boolean)
          )
        );
      }
      if (permissions.not) {
        promises.push(checkComplexPermissions(permissions.not).then((result) => !result));
      }

      return (await Promise.all(promises)).every(Boolean);
    }
  };

  return (
    <PermissionsContext.Provider
      value={{
        flushPermissions: () => (permissionsCache.current = {}),
        checkPermissions: checkComplexPermissions
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}
