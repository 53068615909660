import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { translate } from '../Translate.util';

export default function withTooltip(WrappedComponent) {
  return class extends Component {
    render() {
      const { tooltip, tooltipKey, ...restProps } = this.props;
      const tooltipText = tooltip || translate(tooltipKey) || translate(this.props.tKey + '.tooltip');
      return tooltipText ? (
        <>
          <span data-tip={tooltipText}>
            <WrappedComponent {...restProps} />
          </span>
          <ReactTooltip multinline html />
        </>
      ) : (
        <WrappedComponent {...restProps} />
      );
    }
  };
}
