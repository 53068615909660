import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function withInteraction(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      onClick: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      onKeyPress: PropTypes.func,
      onKeyDown: PropTypes.func,
      onKeyUp: PropTypes.func,
      onTouchStart: PropTypes.func,
      onTouchEnd: PropTypes.func,
      onTouchCancel: PropTypes.func,
      onMouseDown: PropTypes.func,
      onMouseEnter: PropTypes.func,
      onMouseLeave: PropTypes.func
    };

    constructor(props) {
      super(props);

      this.state = {
        // used for touch devices like iOS Chrome/Safari where the active
        // pseudoClass is not supported on touch
        isActive: props.buttonState && props.buttonState === 'focus',
        isHovered: props.buttonState && props.buttonState === 'hover',

        // Note: On touch devices mouseEnter is fired while mouseLeave is not.
        // This would result in a hover effect that keeps active until another
        // element is hasFocus on. This would result in the same behaviour asElement using
        // the :hover pseudo class. To prevent it from happening activating the
        // hover state is prevented when a touch event has been triggered before.
        // source: http://stackoverflow.com/a/22444532/837709
        isIgnoringHover: false
      };

      // The hasFocus attribute is used to apply the one-time focus animation.
      // As it is reset after every render it can't be set inside state asElement this
      // would trigger an endless loop.
      this.hasFocus = props.buttonState && props.buttonState === 'focus';

      // This used to determine if the one-time focus animation should be prevented.
      this.mouseDownOnButton = false;

      this.disabled = props.buttonState && props.buttonState === 'disabled';
    }

    /**
     * Deactivate the hasFocus attribute in order to make sure the focus animation
     * only runs once when the component is hasFocus on & not after re-rendering
     * e.g when the user clicks the button.
     */

    componentDidUpdate() {
      this.focused = false;
      this.mouseDownOnButton = false;
    }

    handleClick = (event) => {
      if (event.button === 0 && !this.disabled) {
        this.mouseDownOnButton = true;
      }

      if (this.props.onClick && !this.disabled) {
        this.props.onClick(event);
      }
    };

    /**
     * Activate the hasFocus attribute used to determine when to show the
     * one-time focus animation and trigger a render.
     */
    handleFocus = (event) => {
      this.hasFocus = true;
      this.forceUpdate();

      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    };

    /**
     * Deactivate the hasFocus attribute used to determine when to show the
     * one-time focus animation and trigger a render.
     */
    handleBlur = (event) => {
      this.setState({ isActive: false });

      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    };

    handleMouseDown = (event) => {
      if (event && event.button === 0 && !this.props.disabled) {
        this.mouseDownOnButton = true;
      }

      if (this.props.onMouseDown) {
        this.props.onMouseDown(event);
      }
    };

    handleKeyPress = (event) => {
      if (!this.props.disabled) {
        this.mouseDownOnButton = true;
      }

      if (this.props.onKeyPress) {
        this.props.onKeyPress(event);
      }
    };

    /**
     * Updates the button to be pressed.
     */
    handleTouchStart = (event) => {
      if (!this.props.disabled && event.touches.length === 1) {
        this.setState({
          isActive: true,
          isIgnoringHover: true
        });
      }

      if (this.props.onTouchStart) {
        this.props.onTouchStart(event);
      }
    };

    /**
     * Updates the button to be release.
     */
    handleTouchEnd = (event) => {
      this.setState({
        isActive: false,
        isIgnoringHover: true
      });

      if (this.props.onTouchEnd) {
        this.props.onTouchEnd(event);
      }
    };

    /**
     * Updates the button to be release.
     */
    handleTouchCancel = (event) => {
      this.setState({
        isActive: false,
        isIgnoringHover: true
      });

      if (this.props.onTouchEnd) {
        this.props.onTouchEnd(event);
      }
    };

    /**
     * As soon asElement the mouse enters the component the isHovered state is activated.
     */
    handleMouseEnter = (event) => {
      if (!this.state.isIgnoringHover) {
        this.setState({
          isHovered: true,
          isIgnoringHover: false
        });
      }

      if (this.props.onMouseEnter) {
        this.props.onMouseEnter(event);
      }
    };

    /**
     * Deactivate the isHovered state.
     */
    handleMouseLeave = (event) => {
      this.setState({
        isHovered: false
      });

      if (this.props.onMouseLeave) {
        this.props.onMouseLeave(event);
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onClick={this.handleClick}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onTouchStart={this.handleTouchStart}
          onTouchEnd={this.handleTouchEnd}
          onTouchCancel={this.handleTouchCancel}
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onKeyPress={this.handleKeyPress}
        />
      );
    }
  };
}
