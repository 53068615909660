import { fetchResponseForParameter, putContent, getUploadUrl } from './api-helper';

const dispoPoliciesUrl = `${process.env.REACT_APP_SPN_SURETY_DISPO_API}/policies`;
const dispoPoliciesIdUrl = (policyId) => `${dispoPoliciesUrl}/${policyId}`;
export const dispoPolicyDocumentsUrl = (policyId, documentType) => `${dispoPoliciesIdUrl(policyId)}/${documentType}`;

export const putDispoPolicyById = (policyId, policy) => putContent(dispoPoliciesIdUrl(policyId), policy);

export const getDispoPolicyById = (policyId) => fetchResponseForParameter(dispoPoliciesIdUrl(policyId), null);

export const getDispoPolicyDocumentById = (policyId, documentType) =>
  fetchResponseForParameter(dispoPolicyDocumentsUrl(policyId, documentType));

export const getDispoPolicyUploadUrl = (policyId, documentType) =>
  getUploadUrl(dispoPolicyDocumentsUrl(policyId, documentType));
