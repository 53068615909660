import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Icon from '../../static/icon/Icon';

import './Modal.scss';

const Modal = (props) => {
  const { size, children, closeButton, onCloseAction } = props;
  const contentClass = `modal-content-${size}`;
  return (
    <ReactModal {...props} overlayClassName='modal-overlay' className='modal-container' ariaHideApp={false}>
      <div className={contentClass}>
        {closeButton && (
          <div className='float-right'>
            <Icon iconName='cross' onClick={onCloseAction} fillStyle='outline' className='cursor-pointer' />
          </div>
        )}
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};

const sizes = ['small', 'medium', 'large'];

Modal.propTypes = {
  size: PropTypes.oneOf(sizes),
  closeButton: PropTypes.bool,
  onCloseAction: PropTypes.func
};

Modal.defaultProps = {
  size: 'medium',
  closeButton: true
};

export default Modal;
