import { deleteCookie, getCookie, setCookie } from './cookie';

export const ABP_TOKEN_COOKIE = 'abp-token';
export const ABP_VTNR_COOKIE = 'abp-vtnr';
export const ABP_PERID_COOKIE = 'abp-perid';
export const ABP_PRODUCT_CODE_COOKIE = 'abp-product-code';

export function getAllianzHeaders() {
  const headers = {};

  const abpToken = getCookie(ABP_TOKEN_COOKIE);
  if (abpToken) {
    headers['x-spn-abp-token'] = abpToken;
  }

  const abpPerId = getCookie(ABP_PERID_COOKIE);
  if (abpPerId) {
    headers['x-spn-perid'] = abpPerId;
  }

  const abpVtnr = getCookie(ABP_VTNR_COOKIE);
  if (abpVtnr) {
    headers['x-spn-vtnr'] = abpVtnr;
  }

  const abpProductCode = getCookie(ABP_PRODUCT_CODE_COOKIE);
  if (abpProductCode) {
    headers['x-spn-product-code'] = abpProductCode;
  }

  return headers;
}

function getProductCodeFrom(params) {
  for (const key of params.keys()) {
    if (key.toLocaleLowerCase() === 'simplicity') {
      return 'simplicity';
    }
    if (key.toLocaleLowerCase() === 'vsv') {
      return 'fidelity';
    }
    if (key.toLocaleLowerCase() === 'aval') {
      return 'surety';
    }
  }
  return '';
}

export function flushAllianzCookies() {
  deleteCookie(ABP_TOKEN_COOKIE);
  deleteCookie(ABP_VTNR_COOKIE);
  deleteCookie(ABP_PERID_COOKIE);
  deleteCookie(ABP_PRODUCT_CODE_COOKIE);
}

export function setAllianzCookies() {
  if (hasAllianzParams()) {
    const params = new URLSearchParams(window.location.search);
    setCookie(ABP_PERID_COOKIE, params.get('pid'));
    setCookie(ABP_TOKEN_COOKIE, params.get('aid'));
    setCookie(ABP_VTNR_COOKIE, params.get('vtnr'));
    setCookie(ABP_PRODUCT_CODE_COOKIE, getProductCodeFrom(params));
  }
}

export function hasAllianzParams() {
  const params = new URLSearchParams(window.location.search);
  return (
    params.has('aid') ||
    params.has('vtnr') ||
    params.has('pid') ||
    params.has('simplicity') ||
    params.has('aval') ||
    params.has('vsv')
  );
}
