import {
  fetchListForParameter,
  fetchResponseForParameter,
  getDocumentDownloadUrl,
  getDocumentUploadUrl,
  patchContent,
  postContent
} from './api-helper';

const policiesUrl = (product) => `${process.env.REACT_APP_SPN_POLICIES_API}/${product}`;
const policiesIdUrl = (product, policyId) => policiesUrl(product) + `/${policyId}`;

export const getPolicies = async (
  product,
  parameter,
  page,
  pageSize,
  sort,
  filterDuplicatedPolicies,
  filterAbandonedDrafts
) => {
  const getPoliciesUrl = policiesUrl(product);
  return fetchListForParameter(
    getPoliciesUrl,
    parameter,
    page,
    pageSize,
    sort,
    filterDuplicatedPolicies,
    filterAbandonedDrafts
  );
};

export const getPolicyById = async (product, policyId) => {
  const policyByIdUrl = policiesIdUrl(product, policyId);
  return fetchResponseForParameter(policyByIdUrl, null);
};

export const patchPolicyById = async (product, policyId, policy) => {
  const policyByIdUrl = policiesIdUrl(product, policyId);
  return await patchContent(policyByIdUrl, policy);
};

export const postPolicy = async (product, company, gradingJobUrl) => {
  const postPolicyUrl = policiesUrl(product);
  const newPolicy = {
    productCode: product,
    company: company,
    gradingJobUrl: gradingJobUrl
  };
  return postContent(postPolicyUrl, newPolicy);
};

export const postPolicyChange = async (product, policyId) => {
  const postPolicyUrl = policiesUrl(product);
  const newPolicy = { productCode: product, existingPolicyId: policyId };
  return postContent(postPolicyUrl, newPolicy);
};

export const getPolicyDocumentUrl = (product, policyId, attachmentType) =>
  `${policiesIdUrl(product, policyId)}/${attachmentType}`;

export const getUploadSignedUrl = (product, policyId, attachmentType) =>
  getDocumentUploadUrl(getPolicyDocumentUrl(product, policyId, attachmentType));

export const getDownloadSignedUrl = async (product, policyId, attachmentType) =>
  getDocumentDownloadUrl(getPolicyDocumentUrl(product, policyId, attachmentType));
