import Cookies from 'js-cookie';

export function getCookie(name) {
  return Cookies.get(name);
}

export function setCookie(name, value) {
  if (value) {
    Cookies.set(name, value, { path: '/' });
  }
}

export function deleteCookie(name) {
  Cookies.remove(name);
}
