import React, { Component } from 'react';

import './LmrLayout.scss';

export default class LmrLayout extends Component {
  render() {
    return (
      <span className='lmr-layout-frame'>
        {this.props.left || this.props.children ? (
          <span className='lmr-layout-left'>
            {this.props.left ? <span className='lmr-layout-child'>{this.props.left}</span> : null}
            {this.props.children ? <span className='lmr-layout-child'>{this.props.children}</span> : null}
          </span>
        ) : null}
        {this.props.middle ? <span className='lmr-layout-middle'>{this.props.middle}</span> : null}
        {this.props.right ? <span className='lmr-layout-right'>{this.props.right}</span> : null}
      </span>
    );
  }
}
