import React, { createContext, useContext, useState } from 'react';
import i18n from 'i18next';
import { useUserInfo } from '../config/portal-config';

const AdobeContext = createContext({
  setPreviousPathName: (previousPathName) => null,
  pushPageLoadDataLayer: (pageLoadDataLayer, product) => null,
  pushEventDataLayer: (eventDataLayer, product) => null,
  pushCtaDataLayer: (ctaName, product) => null
});

const useAdobeContext = () => {
  const context = useContext(AdobeContext);
  if (!context) {
    throw new Error('No AdobeContext provided.');
  }
  return context;
};

const isProductFromSurety = (product) => {
  return !product || (product && (product.productCode === 'surety' || product.productCode === 'surety-change'));
};

const AdobeProvider = ({ children, appName }) => {
  const [previousPathName, setPreviousPathName] = useState('');
  const userInfo = useUserInfo();

  const context = {
    setPreviousPathName,
    pushPageLoadDataLayer: (pageLoadDataLayer, product) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      if (!isProductFromSurety(product)) {
        return;
      }

      window.adobeDataLayer?.push({
        event: 'Virtual page',
        appName,
        pageName: pageLoadDataLayer.pageName || document.title,
        pageLanguage: i18n.language,
        previousPageName: previousPathName,
        previousPageUrl: previousPathName === '' ? '' : window.location.host + previousPathName,
        loginStatus: true,
        userID: userInfo.id ?? userInfo.externalId,
        ...pageLoadDataLayer,
        brokerId: userInfo.intermediary.intermediaryId,
        userType: userInfo?.email?.includes('@allianz.') ? 'Allianz Broker' : 'Other brokers'
      });
    },
    pushEventDataLayer: (eventDataLayer, product) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      if (!isProductFromSurety(product)) {
        return;
      }

      window.adobeDataLayer?.push({
        ...eventDataLayer,
        appName
      });
    },
    pushCtaDataLayer: (ctaName, product) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      if (!isProductFromSurety(product)) {
        return;
      }

      window.adobeDataLayer?.push({
        appName,
        event: 'click',
        eventType: 'click CTA',
        eventName: ctaName
      });
    }
  };

  return <AdobeContext.Provider value={context}>{children}</AdobeContext.Provider>;
};

export default AdobeProvider;
export { AdobeContext, useAdobeContext };
