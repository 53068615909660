import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { AppColors, FillStyles, getFillableElementStyle, Sizes, States } from '../../style/AppStyles';
import { Icons } from './Icons';

import './Icon.scss';
import withTooltip from '../../util/hoc/WithTooltip.util';
import IconElement from './IconElement';

class IconComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    iconName: PropTypes.oneOf(Icons).isRequired,
    iconColor: PropTypes.oneOf(AppColors),
    borderColor: PropTypes.oneOf(AppColors),
    iconState: PropTypes.oneOf(States),
    fillStyle: PropTypes.oneOf(FillStyles),
    fillColor: PropTypes.oneOf(AppColors),
    size: PropTypes.oneOf(Sizes)
  };

  static defaultProps = {
    iconName: 'alert',
    iconColor: 'text',
    iconState: 'normal',
    borderColor: 'none',
    fillStyle: 'none',
    fillColor: 'none',
    size: 'normal'
  };

  render() {
    const { iconName, iconColor, iconState, borderColor, fillStyle, fillColor, size, ...restProps } = this.props;
    if (iconName) {
      const className = classNames('icon-container', 'icon-' + size, fillStyle);
      return (
        <div
          className={className}
          style={getFillableElementStyle(fillStyle, iconColor, 'none', borderColor, fillColor, iconState)}
        >
          <IconElement {...restProps} iconName={iconName} />
        </div>
      );
    } else return null;
  }
}

export default class Icon extends withTooltip(IconComponent) {}
