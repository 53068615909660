import React from 'react';
import Modal from '../../../base/dialog/modal/Modal';
import LoadingIcon from '../../../base/animation/loadingIcon/LoadingIcon';
import Text from '../../../base/static/text/Text';
import LmrLayout from '../../layout/lmr-layout/LmrLayout';

export default function WaitModal(props) {
  return (
    <Modal isOpen={!!props.modalText || !!props.text} closeButton={props.closeButton}>
      <br />
      <br />
      {props.text ? <Text element='h1'>{props.text}</Text> : <Text element='h2' tKey={props.modalText} />}
      <br />
      <br />
      <LmrLayout middle={<LoadingIcon />} />
      <br />
      <br />
    </Modal>
  );
}
