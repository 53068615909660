import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icons } from './Icons';

import './IconElement.scss';

export default class IconElement extends Component {
  static propTypes = {
    iconName: PropTypes.oneOf(Icons).isRequired
  };

  static defaultProps = {
    iconName: 'alert'
  };

  render() {
    const { className, iconName, size, ...restProps } = this.props;
    if (iconName) {
      const className = classNames(this.props.className, 'icon- ' + size, 'icon-' + iconName);
      return <span {...restProps} className={className} />;
    } else return null;
  }
}
