import { OidcProvider as AxaOidcProvider } from '@axa-fr/react-oidc';
import { Oidc } from '@axa-fr/react-oidc/dist/vanilla';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WaitModal from '../../base/dialog/wait-modal/WaitModal';
import AuthenticatingErrorModal from './authenticating-error-modal/authenticating-error-modal';
import { hasAllianzParams, setAllianzCookies } from '../../../util/allianz-cookie';

const EXTERNAL_USERS_LOGIN_CALLBACK = '/login';
const ALLIANZ_USERS_LANDING_PAGE = '/abp-sso';
const ALLIANZ_USERS_LOGIN_CALLBACK = '/cim/login';

const configuration = {
  authority: process.env.REACT_APP_IDP_ISSUER,
  client_id: process.env.REACT_APP_IDP_CLIENT_ID_EXT,
  redirect_uri: window.location.origin + EXTERNAL_USERS_LOGIN_CALLBACK,
  scope: `openid spn-portal-extern-${uuidv4()} offline_access`
};

const allianzConfiguration = {
  authority: process.env.REACT_APP_IDP_ISSUER,
  client_id: process.env.REACT_APP_IDP_CLIENT_ID_AZ,
  redirect_uri: window.location.origin + ALLIANZ_USERS_LOGIN_CALLBACK,
  scope: `openid spn-portal-allianz-${uuidv4()} offline_access`
};

const OidcLoadingComponent = () => <WaitModal text='Smart Portal' closeButton={false} />;

const SessionLostComponent = () => {
  useEffect(async () => {
    await Oidc.get().destroyAsync();
    document.location.reload();
  }, []);

  return <OidcLoadingComponent />;
};

export default function OidcProvider({ children }) {
  const [allianz, setAllianz] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isAllianzUser =
      hasAllianzParams() ||
      document.location.pathname.startsWith(ALLIANZ_USERS_LANDING_PAGE) ||
      document.location.pathname.startsWith(ALLIANZ_USERS_LOGIN_CALLBACK) ||
      localStorage.getItem('AZ_USER') === 'true';

    setAllianz(isAllianzUser);

    if (isAllianzUser) {
      setAllianzCookies();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    localStorage.setItem('AZ_USER', allianz.toString());
  }, [allianz]);

  useEffect(() => {
    if (document.location.pathname.startsWith(EXTERNAL_USERS_LOGIN_CALLBACK)) {
      setAllianz(false);
    }
  }, [document.location.pathname]);

  return loading ? (
    <OidcLoadingComponent />
  ) : (
    <AxaOidcProvider
      configuration={allianz ? allianzConfiguration : configuration}
      loadingComponent={OidcLoadingComponent}
      authenticatingComponent={OidcLoadingComponent}
      callbackSuccessComponent={OidcLoadingComponent}
      sessionLostComponent={SessionLostComponent}
      authenticatingErrorComponent={AuthenticatingErrorModal}
      callbackErrorComponent={AuthenticatingErrorModal}
    >
      {children}
    </AxaOidcProvider>
  );
}
