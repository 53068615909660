import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  AppColors,
  getFontStyleClass,
  getTextElementStyle,
  Sizes,
  TextElements,
  TextStyles
} from '../../style/AppStyles';
import './Text.scss';
import withTooltip from '../../util/hoc/WithTooltip.util';
import TranslateUtil from '../../util/Translate.util';

class TextComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    tKey: PropTypes.string,
    className: PropTypes.string,
    asElement: PropTypes.oneOf(TextElements),
    size: PropTypes.oneOf(Sizes),
    textStyle: PropTypes.oneOf(TextStyles),
    textColor: PropTypes.oneOf(AppColors)
  };

  static defaultProps = {
    asElement: 'span',
    size: 'normal',
    textStyle: 'none',
    textColor: 'text'
  };

  render() {
    const { tKey, asElement, size, style, textStyle, textColor, ...rest } = this.props;
    const translate = new TranslateUtil(tKey);
    const className = classNames(getFontStyleClass(textStyle), this.props.className, textStyle);
    const renderStyle = {
      ...style,
      ...getTextElementStyle(size, textStyle, textColor)
    };
    const content = translate.getContent({
      ...rest,
      className: className,
      style: renderStyle
    });
    switch (asElement) {
      case 'h1':
        return <h1>{content}</h1>;
      case 'h2':
        return <h2>{content}</h2>;
      case 'h3':
        return <h3>{content}</h3>;
      case 'h4':
        return <h4>{content}</h4>;
      case 'h5':
        return <h5>{content}</h5>;
      case 'h6':
        return <h6>{content}</h6>;
      case 'p':
        return <p>{content}</p>;
      case 'div':
        return <div>{content}</div>;
      default:
        return content;
    }
  }
}

export default class Text extends withTooltip(TextComponent) {}
