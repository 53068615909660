import React from 'react';

import Modal from '../../../base/dialog/modal/Modal';
import { addLocaleBundle } from '../../../base/util/Translate.util';
import Text from '../../../base/static/text/Text';
import componentLocale from './authenticating-error-modal.json';
import LmrLayout from '../../../base/layout/lmr-layout/LmrLayout';
import Button from '../../../base/buttons/button/Button';
import { Oidc } from '@axa-fr/react-oidc/dist/vanilla';

addLocaleBundle(componentLocale);

const tKey = (element) => 'portal.authenticating-error-modal.' + element;

export default function AuthenticatingErrorModal() {
  const onRetry = async () => {
    document.location.href = '/';
    await Oidc.get().destroyAsync();
  };

  return (
    <Modal isOpen closeButton={false}>
      <strong>
        <Text element='h1' tKey={tKey('title')} />
      </strong>
      <br />
      <br />
      <Text element='p' tKey={tKey('content')} />
      <br />
      <br />
      <LmrLayout middle={<Button tKey={tKey('retry')} onClick={onRetry} />} />
    </Modal>
  );
}
