import { companySearch } from './company-search-api';
import { getStaticPdf } from './pdf-api';
import {
  getDownloadSignedUrl,
  getPolicies,
  getPolicyById,
  getUploadSignedUrl,
  patchPolicyById,
  postPolicy,
  postPolicyChange
} from './policy-api';
import { getUserInfo } from './user-api';
import { getRadarLivePricing } from './radar-live-api';
import { getActiveAlerts } from './alerting-api';
import { checkPermission } from './resource-manager-api.js';
import {
  putDispoPolicyById,
  getDispoPolicyUploadUrl,
  getDispoPolicyById,
  getDispoPolicyDocumentById
} from './surety-dispo-api';

let apiCallVector = {
  companySearch: companySearch,
  getStaticPdf: getStaticPdf,
  getPolicies: getPolicies,
  getPolicyById: getPolicyById,
  patchPolicyById: patchPolicyById,
  postPolicy: postPolicy,
  postPolicyChange: postPolicyChange,
  getUploadSignedUrl: getUploadSignedUrl,
  getDownloadSignedUrl: getDownloadSignedUrl,
  getUserInfo: getUserInfo,
  getRadarLivePricing: getRadarLivePricing,
  getActiveAlerts: getActiveAlerts,
  putDispoPolicyById,
  getDispoPolicyUploadUrl,
  getDispoPolicyById,
  getDispoPolicyDocumentById,
  checkPermission
};

export const setApiCallVector = (vector) => {
  apiCallVector = vector;
};

export const apiCalls = {
  companySearch: (search, page, pageSize) => {
    return apiCallVector.companySearch(search, page, pageSize);
  },
  companyGrades: (product, companyId) => {
    return apiCallVector.companyGrades(companyId);
  },
  getStaticPdf: async (staticPdfCode) => {
    return apiCallVector.getStaticPdf(staticPdfCode);
  },
  getPoliciesUnfiltered: (product, parameter, page, pageSize, sort) => {
    return apiCallVector.getPolicies(product, parameter, page, pageSize, sort);
  },
  getPolicies: (product, parameter, page, pageSize, sort, filterDuplicatedPolicies, filterAbandonedDrafts) => {
    return apiCallVector.getPolicies(
      product,
      parameter,
      page,
      pageSize,
      sort,
      filterDuplicatedPolicies,
      filterAbandonedDrafts
    );
  },
  getPolicyById: (product, policyId) => {
    return apiCallVector.getPolicyById(product, policyId);
  },
  patchPolicyById: (product, policyId, policy) => {
    return apiCallVector.patchPolicyById(product, policyId, policy);
  },
  postPolicy: (product, company, gradingJobUrl) => {
    return apiCallVector.postPolicy(product, company, gradingJobUrl);
  },
  postPolicyChange: (product, policyId) => {
    return apiCallVector.postPolicyChange(product, policyId);
  },
  getUploadSignedUrl: (product, policyId, attachmentType) => {
    return apiCallVector.getUploadSignedUrl(product, policyId, attachmentType);
  },
  getDownloadSignedUrl: (product, policyId, attachmentType) => {
    return apiCallVector.getDownloadSignedUrl(product, policyId, attachmentType);
  },
  getUserInfo: () => {
    return apiCallVector.getUserInfo();
  },
  getRadarLivePricing: (parameter) => {
    return apiCallVector.getRadarLivePricing(parameter);
  },
  getActiveAlerts: () => {
    return apiCallVector.getActiveAlerts();
  },
  putDispoPolicyById: (policyId, policy) => apiCallVector.putDispoPolicyById(policyId, policy),
  getDispoPolicyById: (policyId) => apiCallVector.getDispoPolicyById(policyId),
  getDispoPolicyDocumentById: (policyId, documentType) =>
    apiCallVector.getDispoPolicyDocumentById(policyId, documentType),
  getDispoPolicyUploadUrl: (policyId, documentType, document) =>
    apiCallVector.getDispoPolicyUploadUrl(policyId, documentType, document),
  checkPermission: (actionsTypes) => apiCallVector.checkPermission(actionsTypes)
};
